/* eslint-disable max-lines */
import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import { objectToUrl } from 'Utils/ajax-utils'
import {
  CREATE_APPOINTMENT,
  REQUEST_CANCEL,
  GET_CAL_LIST,
  SEARCH_PATIENT,
  GET_PATIENT_BY_ID,
  GET_PATIENT_BY_ID_DIALOG,
  GET_PATIENT_BY_APT_ID,
  UPDATE_APT_DETAILS,
  GET_SEARCH_PATIENT_BY_APT_ID,
  ZIP_SEARCH,
  ZIP_SEARCH_PAYER_ADDRESS,
  ZIP_SAVE_POLICY_HOLDER,
  ZIP_SEARCH_ACCORDIAN_ADDRESS,
  DRAG_APPOINTMENT,
  APPOINTMENT_DATA,
  DELETE_APPOINTMENT,
  CANCEL_APPOINTMENT,
  APOINTMENT_INFO_INSURANCE,
  TRIGGER_APPT_REMINDER_REVIEW,
} from './calender.types'
import {
  CreateAppointmentResponse,
  GetCalenderlistResponse,
  SearchPatientResponse,
  GetPatientByIdResponse,
  GetPatientByAptIdResponse,
  UpdateAptDetailsResponse,
  GetSearchPatientByAptIdResponse,
  getAddressFromZipRes,
  getAddressFromPayerAddressZipRes,
  savePolicyHolderZipRes,
  getAddressFromAccordianZipRes,
  dragAppointmentResponse,
  appointmentDataResponse,
  deleteAppointmentRes,
  GetPatientByIdDialogResponse,
  cancelAppointmentRes,
  getApointmentinfoInsuranceResponse,
  triggerApptReminderReviewRes,
} from './calender.actions'
import toast from 'react-hot-toast'
const { baseUrl } = environment
/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example Name of the epic = (params) =>
   params.pipe(
   ofType(TYPE_NAME),
   switchMap((action) =>
   ajax({
          url: 'API url',
          method: 'POST/GET',
          body: passing payload from action.js,
        }).pipe(
   map(
   (response) => Response action(response),
   takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
   ),
   ),
   ),
   )
  })
 */

const calenderEpic = {}

/**
 * createAppointment
 * @description - Calling create appointment API for follow up patient
 * @params {action} - params of  CREATE_APPOINTMENT type from action.js, {action$} - params from action.js
 */
calenderEpic.createAppointment = (action$) =>
  action$.pipe(
    ofType(CREATE_APPOINTMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_createAppointment}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => CreateAppointmentResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * getCalenderList
 * @description - loading appointments in full calender
 * @params {action} - params of  GET_CAL_LIST type from action.js, {action$} - params from action.js
 */
calenderEpic.getCalenderList = (action$) =>
  action$.pipe(
    ofType(GET_CAL_LIST),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getCalenderList}/${action.payload.date}?providerIds=${action.payload.provider}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => GetCalenderlistResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * searchPatient
 * @description -  to call search patient api in create appointment popup
 * @params {action} - params of  SEARCH_PATIENT type from action.js, {action$} - params from action.js
 */
calenderEpic.searchPatient = (action$) =>
  action$.pipe(
    ofType(SEARCH_PATIENT),
    switchMap((action) =>
      ajax({
        url: objectToUrl(
          `${baseUrl}/${Apis.Calendar_searchPatient}`,
          action.payload,
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => SearchPatientResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * getPatientUsingId
 * @description -  to get the patient details by patient id in create appointment popup while we click on the table row
 * @params {action} - params of  GET_PATIENT_BY_ID type from action.js, {action$} - params from action.js
 */
calenderEpic.getPatientUsingId = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_BY_ID),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getPatientUsingId}/${action.payload}`,
        method: 'GET',
        body: action.payload,
      }).pipe(
        map(
          (response) => GetPatientByIdResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

calenderEpic.getPatientUsingIdDialog = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_BY_ID_DIALOG),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getPatientUsingIdDialog}/${action.payload}`,
        method: 'GET',
        body: action.payload,
      }).pipe(
        map(
          (response) => GetPatientByIdDialogResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * getPatientUsingAptId
 * @description -  to get the patient details by appointment id  in accordian
 * @params {action} - params of  GET_PATIENT_BY_APT_ID type from action.js, {action$} - params from action.js
 */
calenderEpic.getPatientUsingAptId = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_BY_APT_ID),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getPatientUsingAptId}/${action.payload}`,
        method: 'GET',
        // body: action.payload,
      }).pipe(
        map(
          (response) => GetPatientByAptIdResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

calenderEpic.getApointmentinfoInsurance = (action$) =>
  action$.pipe(
    ofType(APOINTMENT_INFO_INSURANCE),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getApointmentinfoInsurance}/${action.payload}`,
        method: 'GET',
        // body: action.payload,
      }).pipe(
        map(
          (response) => getApointmentinfoInsuranceResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * updateAptDetails
 * @description -  to update the appointment details by appointment id in accordian
 * @params {action} - params of  UPDATE_APT_DETAILS type from action.js, {action$} - params from action.js
 */
calenderEpic.updateAptDetails = (action$) =>
  action$.pipe(
    ofType(UPDATE_APT_DETAILS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_updateAptDetails}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => UpdateAptDetailsResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * getSearchPatientUsingId
 * @description - to call the API to get the patient details from appointment id
 * @params {action} - params of  GET_SEARCH_PATIENT_BY_APT_ID type from action.js, {action$} - params from action.js
 */
calenderEpic.getSearchPatientUsingId = (action$) =>
  action$.pipe(
    ofType(GET_SEARCH_PATIENT_BY_APT_ID),
    switchMap((action) =>
      ajax({
        url: `/api/v1/patientreg/getPatientWithAppointment?id=${action.payload}`,
        method: 'GET',
        // body: action.payload,
      }).pipe(
        map(
          (response) => GetSearchPatientByAptIdResponse(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * getAddressFromZipCode
 * @description - to call api to list city, state and country a/c zip code in create appointment
 * @params {action} - params of  ZIP_SEARCH type from action.js, {action$} - params from action.js
 */
calenderEpic.getAddressFromZipCode = (action$) =>
  action$.pipe(
    ofType(ZIP_SEARCH),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getAddressFromZipCode}?zipCode=${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAddressFromZipRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * getAddressFromPayerAddZipCode
 * @description - to call api to list city, state and country a/c zip code in payer address
 * @params {action} - params of  ZIP_SEARCH_PAYER_ADDRESS type from action.js, {action$} - params from action.js
 */
calenderEpic.getAddressFromPayerAddZipCode = (action$) =>
  action$.pipe(
    ofType(ZIP_SEARCH_PAYER_ADDRESS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getAddressFromPayerAddZipCode}?zipCode=${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAddressFromPayerAddressZipRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * getAddressFromAccordianAddZipCode
 * @description - to call api to list city, state and country a/c zip code in accordian section
 * @params {action} - params of  ZIP_SEARCH_ACCORDIAN_ADDRESS type from action.js, {action$} - params from action.js
 */
calenderEpic.getAddressFromAccordianAddZipCode = (action$) =>
  action$.pipe(
    ofType(ZIP_SEARCH_ACCORDIAN_ADDRESS),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_getAddressFromAccordianAddZipCode}?zipCode=${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAddressFromAccordianZipRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * savePolicyHolderZipCode
 * @description - to call api to list city, state and country a/c zip code in the policy holder
 * @params {action} - params of  ZIP_SAVE_POLICY_HOLDER type from action.js, {action$} - params from action.js
 */
calenderEpic.savePolicyHolderZipCode = (action$) =>
  action$.pipe(
    ofType(ZIP_SAVE_POLICY_HOLDER),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_savePolicyHolderZipCode}?zipCode=${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePolicyHolderZipRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * dragAppointment
 * @description - to call api to update patient details in accordian in case of drag event
 * @params {action} - params of  DRAG_APPOINTMENT type from action.js, {action$} - params from action.js
 */
calenderEpic.dragAppointment = (action$) =>
  action$.pipe(
    ofType(DRAG_APPOINTMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/trillium-clinic-service/v1/appointment/{id}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => dragAppointmentResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
/**
 * dragAppointment
 * @description - to call api to get the appointment by id in case of drag event
 * @params {action} - params of  APPOINTMENT_DATA type from action.js, {action$} - params from action.js
 */
calenderEpic.dragAppointmentData = (action$) =>
  action$.pipe(
    ofType(APPOINTMENT_DATA),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_dragAppointmentData}/${action.payload}`,
        method: 'GET',
        // body: action.payload,
      }).pipe(
        map(
          (response) => appointmentDataResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

/**
 * dragAppointment
 * @description - to call api to get the appointment by id in case of drag event
 * @params {action} - params of  APPOINTMENT_DATA type from action.js, {action$} - params from action.js
 */
calenderEpic.deleteAppointment = (action$) =>
  action$.pipe(
    ofType(DELETE_APPOINTMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_deleteAppointment}/${action.payload}`,
        method: 'DELETE',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => deleteAppointmentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
calenderEpic.cancelAppoinmentApi = (action$) =>
  action$.pipe(
    ofType(CANCEL_APPOINTMENT),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_cancelAppoinmentApi}/${action.payload.id}?appointmentReminderStatus=${action.payload.appointmentReminderStatus}&appointmentStatus=${action.payload.appointmentStatus}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => cancelAppointmentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

calenderEpic.triggerApptReminderReview = (action$) =>
  action$.pipe(
    ofType(TRIGGER_APPT_REMINDER_REVIEW),
    switchMap((action) =>
      ajax({
        url: `${baseUrl}/${Apis.Calendar_triggerApptReminderReview}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map((response) => {
          const res = triggerApptReminderReviewRes(response)

          if (response.response.data) {
            if (response.response.data.messageConfigtype === 6) {
              toast.success(
                'Appointment reminder message has been sent successfully',
              )
            } else if (response.response.data.messageConfigtype === 8) {
              toast.success('Review message has been sent successfully')
            }
          } else if (response.response.responseCode === 158) {
            toast.error('Messages have been blocked by this patient')
          } else if (response.response.responseCode === 161) {
            toast.error(
              response.response.error.message
                ? response.response.error.message[161]
                : 'Send Reminder is not enabled for this appointment type.',
            )
          }

          return res
        }),
        takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
      ),
    ),
  )
export default calenderEpic
