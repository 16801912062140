/* eslint-disable max-lines */
import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { ajax as UtilsAjax } from 'Utils'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
import {
  ZIPCODE_FOR_DEMOGRAPHICS,
  REQUEST_CANCEL,
  GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS,
  EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS,
  SEARCH_PAYER_INSURANCE_TAB,
  SEARCH_PAYER_ADDRESS_INSURANCE_TAB,
  GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS,
  GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB,
  EDIT_SAVE_INSURANCE_TAB,
  STATUS_CHANGE_OF_INSURNACE,
  GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID,
  SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID,
  GET_NOTES_FOR_APPOINTMENT,
  SAVE_NOTES_FOR_APPOINTMENT,
  GET_APPOINTMENT_LIST_ACCOUNTS,
  CANCEL_APPOINTMENT_LIST_ACCOUNTS,
  GET_ACCOUNTS,
  CLAIM_LIST,
  CLAIM_DOWNLOAD,
  SAVE_PAYMENTS,
  WRITEOFF,
  PAYMENT_LIST,
  BALANCE_LIST,
  STATEMENT_LIST,
  AUTHORIZATIONS,
  PAYMENT_DELETE,
  STRIPE_PAYMENT,
  STATEMENT_DETAILS,
  GET_PIN,
  GET_CLAIM_NOTES,
} from './accounts.types'
import {
  searchZipcodeInDemographicsRes,
  getPatientForDemographicsTabRes,
  editAndSavePatientDemographicsRes,
  searchPayerInInsurnaceTabRes,
  searchPayerAddressInInsuranceTabRes,
  getPoliciesByPatientIdDemographicsRes,
  getPolicyHolderByPatientIdInsuranceTabRes,
  editAndSaveinsurnaceTabRes,
  changeStatusOfInsurnaceRes,
  getNotesByPatientIdAccountsRes,
  saveNotesByPatientIdAccountsRes,
  getNotesAppointmentRes,
  saveNotesByAppointmentRes,
  getAppointmentListRes,
  cancelAppointmentListRes,
  getAccountsRes,
  claimListRes,
  claimDownloadRes,
  savePaymentRes,
  writeOffRes,
  paymentListRes,
  balanceListRes,
  statementListRes,
  authorizationListRes,
  paymentDeleteRes,
  stripePaymentRes,
  statementDetailsRes,
  getPatientPinRes,
  getClaimsNotesResponse,
} from './accounts.actions'
const { baseUrl } = environment
const { baseUrl2 } = environment
const AccountsEpic = {}

AccountsEpic.getAddressFromZipCode = (action$) =>
  action$.pipe(
    ofType(ZIPCODE_FOR_DEMOGRAPHICS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getAddressFromZipCode}?zipCode=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchZipcodeInDemographicsRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getPatientByIdForTab = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_BY_ID_FOR_DEMOGRAPHICS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getPatientByIdForTab}/${action.payload}`,
        method: 'GET',
        body: action.payload,
      }).pipe(
        map(
          (response) => getPatientForDemographicsTabRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.editAndSavePatient = (action$) =>
  action$.pipe(
    ofType(EDIT_AND_SAVE_PATIENT_DEMOGRAPHICS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_editAndSavePatient}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => editAndSavePatientDemographicsRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getAccountsData = (action$) =>
  action$.pipe(
    ofType(GET_ACCOUNTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getAccountsData}/${action.payload.patientId}?isCptLevel=${action.payload.status}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getAccountsRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getClaimlistApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getClaimlistApi}/${action.payload.claimId}?patientId=${action.payload.patientId}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => claimListRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.claimDownloadApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_DOWNLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 2,
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        // url: `${baseUrl}/${Apis.Accounts_claimDownloadApi}?claimId=${action.payload.claimId}&clinicId=${action.payload.clinicId}&fileId=${action.payload.fileId}&fileName=${action.payload.fileName}`,
        url: `${baseUrl}/${Apis.Accounts_claimDownloadApi}?fileId=${action.payload.fileId}&fileName=${action.payload.fileName}&isInternal=false`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => claimDownloadRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getPaymentListApi = (action$) =>
  action$.pipe(
    ofType(PAYMENT_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getPaymentListApi}/${action.payload}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => paymentListRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.savePaymentApi = (action$) =>
  action$.pipe(
    ofType(SAVE_PAYMENTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_savePaymentApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePaymentRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.writeOffApi = (action$) =>
  action$.pipe(
    ofType(WRITEOFF),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_writeOffApi}/${action.payload.claimId}?procedureId=${action.payload.procedureId}&status=11`,
        method: 'PUT',
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => writeOffRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.balanceListApi = (action$) =>
  action$.pipe(
    ofType(BALANCE_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_balanceListApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => balanceListRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.statementListApi = (action$) =>
  action$.pipe(
    ofType(STATEMENT_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_statementListApi}/${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => statementListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getStatementsDetailsApi = (action$) =>
  action$.pipe(
    ofType(STATEMENT_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getStatementsDetailsApi}/${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => statementDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.authorizationApi = (action$) =>
  action$.pipe(
    ofType(AUTHORIZATIONS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_authorizationApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => authorizationListRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.paymentDeleteApi = (action$) =>
  action$.pipe(
    ofType(PAYMENT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_paymentDeleteApi}/${action.payload}`,
        method: 'DELETE',
      }).pipe(
        map(
          (response) => paymentDeleteRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.searchPayer = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYER_INSURANCE_TAB),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_searchPayer}?name=${action.payload.s}`,
        method: 'GET',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => searchPayerInInsurnaceTabRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.searchPayerAddress = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYER_ADDRESS_INSURANCE_TAB),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_searchPayerAddress}?clinicId=${action.payload.clinicId}&payerId=${action.payload.payerId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPayerAddressInInsuranceTabRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getPolicyByPatientId = (action$) =>
  action$.pipe(
    ofType(GET_POLICIES_BY_PATIENTID_DEMOGRAPHICS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getPolicyByPatientId}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPoliciesByPatientIdDemographicsRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getPolicyHolderInsurnaceTab = (action$) =>
  action$.pipe(
    ofType(GET_POLICIY_HOLDER_BY_PATIENT_ID_INSURANCE_TAB),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getPolicyHolderInsurnaceTab}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPolicyHolderByPatientIdInsuranceTabRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.editAndSavePatientInsurnace = (action$) =>
  action$.pipe(
    ofType(EDIT_SAVE_INSURANCE_TAB),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_editAndSavePatientInsurnace}?relation=${action.payload.relation}`,
        method: 'POST',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => editAndSaveinsurnaceTabRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.changeStatusOfInsurnace = (action$) =>
  action$.pipe(
    ofType(STATUS_CHANGE_OF_INSURNACE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_changeStatusOfInsurnace}/${action.payload.id}?active=${action.payload.status}&id=${action.payload.id}`,
        method: 'PUT',
        body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => changeStatusOfInsurnaceRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getPatientNotesAccounts = (action$) =>
  action$.pipe(
    ofType(GET_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getPatientNotesAccounts}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getNotesByPatientIdAccountsRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.savePatientNotesAccounts = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTES_FOR_ACCOUNTS_BY_PATIENT_ID),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_savePatientNotesAccounts}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveNotesByPatientIdAccountsRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getNotesAppointment = (action$) =>
  action$.pipe(
    ofType(GET_NOTES_FOR_APPOINTMENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getNotesAppointment}/${action.payload}`,
        method: 'GET',
        // body: JSON.stringify(action.payload.data),
      }).pipe(
        map(
          (response) => getNotesAppointmentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.saveNotesAppointment = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTES_FOR_APPOINTMENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_saveNotesAppointment}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveNotesByAppointmentRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getAppoinmentList = (action$) =>
  action$.pipe(
    ofType(GET_APPOINTMENT_LIST_ACCOUNTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getAppoinmentList}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getAppointmentListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.cancelAppoinmentList = (action$) =>
  action$.pipe(
    ofType(CANCEL_APPOINTMENT_LIST_ACCOUNTS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_cancelAppoinmentList}/${action.payload.id}?id=${action.payload.id}&status=${action.payload.status}`,
        method: 'PUT',
      }).pipe(
        map(
          (response) => cancelAppointmentListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.stripePaymentApi = (action$) =>
  action$.pipe(
    ofType(STRIPE_PAYMENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_stripePaymentApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => stripePaymentRes(response),
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getPatientPinApi = (action$) =>
  action$.pipe(
    ofType(GET_PIN),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Accounts_getPatientPinApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPatientPinRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
AccountsEpic.getClaimsNotesApi = (action$) =>
  action$.pipe(
    ofType(GET_CLAIM_NOTES),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/trillium-internal-service/v1/common/claim/note/list/${action.payload.claimId}?patientId=${action.payload.patientId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getClaimsNotesResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )
export default AccountsEpic